.button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  @media (max-width: 480px) {
    right: -7px;
  }
}
