.container {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3744f5;
  border-radius: 8px;
  padding: 14px 28px;
  text-align: center;
}
