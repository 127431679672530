.title {
  margin-bottom: 100px;

  @media (max-width: 480px) {
    margin-bottom: 60px;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 24px;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}
.card {
  height: 251px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 680px) {
    height: 175px;
  }
}
.image {
  width: 60px;
  height: 60px;
  display: block;
}
.cardText {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  @media (max-width: 680px) {
    font-size: 16px;
  }
}
