.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #a8b3ce;
  font-family: "Inter";
  @media (max-width: 1280px) {
    br {
      display: contents;
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}
