.header {
  padding: 26px 39px;
  z-index: 2;

  &.desktop {
    @media (max-width: 1280px) {
      display: none;
    }
  }
  &.mobile {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #080808;
    width: 100%;
    display: none;
    @media (max-width: 1280px) {
      display: block;
    }
    @media (max-width: 480px) {
      padding: 20px;
    }
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #a3aec9;
}
.right {
  display: flex;
  align-items: center;
  gap: 32px;
}
.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a8b3ce;
}
.bottom {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
  }
}

@media (max-width: 480px) {
  .logo {
    height: 30px;
    width: 87px;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}
