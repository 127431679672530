:root,
* {
  font-family: "Manrope", sans-serif;
  color: #f3f5f7;
}
body {
  background-color: #080808;
}
a,
button {
  cursor: pointer;
}

ul,
li {
  margin: 0;
  padding: 0;
}

// .icon-placeholder {
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   flex: 40px 0;
//   margin-right: 20px;
// }

// .text-placeholder {
//   border-radius: 5px;
//   width: 200px;
//   height: 20px;
//   flex: 1;
// }

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
