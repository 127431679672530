.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    padding-top: 100px;
  }
}
