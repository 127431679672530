.link {
  display: block;
  padding: 11px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a8b3ce;

  @media (max-width: 1280px) {
    color: inherit;
  }
}
