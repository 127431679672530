.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 24px;
}
.cardBody {
  margin-bottom: 24px;
}
.cardIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 58px;
}
.cardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.cardTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
}
