.form {
  width: 630px;
  & > * {
    width: 100%;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #fff;

  @media (max-width: 480px) {
    padding-top: 20px;
  }
}

.variants {
  margin-top: 44px;
  margin-bottom: 34px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.captcha {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.circle {
  width: 38px;
  height: 38px;
  content: "";
  border: 2px solid #3744f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}
.circleOuter {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 7px solid #3744f5;
  opacity: 0.2;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.dot {
  content: "";
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: #3744f5;
}

.item {
  display: flex;
  align-items: center;
  gap: 14px;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #a8b3ce;
}
.submitContainer {
  position: relative;
  & > * {
    width: 100%;
  }
}
.error {
  left: 0;
  top: -25px;
  position: absolute;
  color: #f53765;
}
