.container {
  height: 900px;
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1280px) {
    height: 600px;
  }
  .bg {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .info {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    a {
      color: #3744f5;
    }
    p {
      margin-bottom: 98px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
      padding: 0 20px;
    }
  }
}
