.wrapper {
  padding: 0 39px;
  margin-bottom: 50px;
  width: 100%;
  @media (max-width: 480px) {
    padding: 0 20px;
  }
}
.container {
  height: 742px;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  @media (max-width: 680px) {
    height: 500px;
  }

  @media (max-width: 480px) {
    border-radius: 20px;
  }
}
.bg {
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.content {
  max-width: 718px;
  padding: 39px;
  padding-bottom: 43px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  position: relative;

  @media (max-width: 680px) {
    justify-content: center;
    gap: 30px;
  }
  @media (max-width: 480px) {
    gap: 20px;
    padding: 30px 20px;
  }
}
.tags {
  display: flex;
  gap: 8px;
  justify-content: center;
}
.title {
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  text-align: center;
  @media (max-width: 680px) {
    font-size: 38px;
  }

  @media (max-width: 480px) {
    font-size: 26px;
  }
}
.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  @media (max-width: 680px) {
    font-size: 16px;
    br {
      display: none;
    }
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
}
