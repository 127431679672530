.form {
  width: 412px;
  display: grid;
  grid-template-columns: 100%;
  gap: 24px;
}
.inputContainer {
  background-color: #161a27;
  border-radius: 8px;
  position: relative;
  border: 1px solid #161a27;
  transition: 0.3s;
  &.danger {
    border: 1px solid #f53765;
  }
  input {
    width: 100%;
    padding: 15px 14px 15px 53px;
    &::placeholder {
      color: #a8b3ce;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    path {
      transition: 0.3s;
    }
    &.filled {
      path {
        fill: #f3f5f7;
      }
    }
  }
  .error {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #f53765;
    position: absolute;
    right: 0;
    bottom: calc(100% + 4px);
  }
}
