.tag {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  padding: 8px 10px;
  border: 1px solid #3744f5;
  border-radius: 8px;

  @media (max-width: 480px) {
    font-size: 10px;
    padding: 6px 8px;
    border-radius: 6px;
  }
}
