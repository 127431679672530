.title {
  margin-bottom: 100px;
  @media (max-width: 480px) {
    margin-bottom: 60px;
  }
}
.grid {
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-template-rows: 365px 365px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-rows: 365px 365px 365px 365px;
  }
}
.card {
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 1280px) {
    padding-bottom: 110px;
    padding-right: 110px;
  }

  @media (max-width: 480px) {
    padding-bottom: 60px;
    padding-right: 60px;
  }
}
.cardBg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 170px;
  height: 170px;
  @media (max-width: 1280px) {
    width: 110px;
    height: 110px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
}
.cardTitle {
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 24px;
}
.textContainer {
  max-width: 328px;
}
