.container {
}
.header {
  background-color: #3744f5;
  height: 91px;
  padding: 30px;
  border-radius: 30px 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding: 20px;
    height: 70px;
  }
}
.title {
  color: #f3f5f7;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: center;
  b {
    font-size: 34px;
    margin-left: 16px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    b {
      font-size: 24px;
    }
  }
}
.body {
  background-color: #0f121c;
  height: 523px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 480px) {
    height: auto;
    min-height: 400px;
  }
}
.footer {
  background: #161a27;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 30px 30px;
  padding: 20px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #a8b3ce;
    text-align: center;
  }
}
