.section {
  padding: 50px 78px 0 78px;
  margin-bottom: 50px;

  @media (max-width: 576px) {
    padding: 50px 30px 0 30px;
  }

  @media (max-width: 480px) {
    padding: 30px 20px 0 20px;
    margin-bottom: 30px;
  }
}
